import React, { useEffect, useState } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { formatLink, throttled, formatLinkGatsby } from "../../util/functions"
import { isTablet, isMobile } from "react-device-detect"
import { Link as Lnk, navigate } from "gatsby"
import DropdownMenu from "./DropdownMenu"
import FullWidthContainer from "../../helper/FullWidthContainer"
import arrow from "../../white-co-images/arrow.svg"

interface NavigationProps {
  slug: string
  links: Array<any>
  enquiryHeading: string
  enquiryText: string
  cta: any
}

const Navigation = ({
  links = [],
  slug,
  enquiryHeading,
  enquiryText,
  cta,
}: NavigationProps) => {
  const [showDropDown, toggleDropDown] = useState(false)
  const [dropdownLinks, setDropdownLinks] = useState([])
  const [superDropdownLinks, setSuperDropdownLinks] = useState([])
  const [mobileDevice, setMobileDevice] = useState(false)
  const [firstLoad, toggleFirstLoad] = useState(true)
  const [allowStateChange, toggleStateChange] = useState(true)

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      setMobileDevice(isTablet || isMobile)
    }
  }, [])

  useEffect(() => {
    let last: number = 0
    const getWindowScroll = () => {
      const nav: HTMLElement | null = document.getElementById(
        "navigation-container"
      )
      const container: HTMLElement | null = document.getElementById(
        "navigation-outer-container"
      )

      const animatedBackground: HTMLElement | null = document.getElementById(
        "animatedBackgroundNav"
      )

      if (typeof window !== "undefined" && typeof document !== "undefined") {
        if (nav && container) {
          let currentScroll: number =
            document.documentElement.scrollTop || document.body.scrollTop

          // scroll down more than 120 -> nav disappear

          if (window.pageYOffset < 150) {
            nav.style.position = "absolute"
            nav.style.cssText = ""
            last = 0
            currentScroll = 0
          } else if (
            currentScroll > 0 &&
            last <= currentScroll &&
            window.pageYOffset > 150
          ) {
            last = currentScroll
            container.style.marginBottom = "60px"
            nav.style.cssText = "position: fixed; top: -100%;"
          }

          // scroll is less than 60 -> nav sticky + logoTop component
          else if (window.pageYOffset <= 150) {
            last = 0
            currentScroll = 0
            nav.style.transition = "none"
            nav.style.cssText = `position:fixed; top: ${60 -
              window.pageYOffset}px; left: 0; right: 0; z-index: 9999;`
            if (animatedBackground) {
              animatedBackground.style.height = "0%"
            }
          }
          // scroll up -> nav appear
          else if (last > currentScroll) {
            last = currentScroll
            nav.style.cssText =
              "position: fixed; top: 0; left: 0; right: 0; z-index: 9999; top 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s; border-bottom: 1px solid #ddd; box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);"

            if (animatedBackground) {
              animatedBackground.style.height = "100%"
            }
          }
        }
      }
    }

    // const eventListener = throttled(0, getWindowScroll)

    window.addEventListener("scroll", getWindowScroll)
    window.addEventListener("resize", () => toggleFirstLoad(true))

    return () => {
      window.removeEventListener("scroll", getWindowScroll)
    }
  }, [])

  const checkHref = (link: string): boolean => {
    if (link === slug) {
      return true
    }
    return false
  }

  const checkBreadcrumb = (breadcrumb: string, superDropdown: any) => {
    if (!slug) {
      return false
    }
    if (superDropdown) {
      for (let i = 0; i < links.length; i++) {
        if (slug === formatLink(links[i].uri)) {
          return false
        }
      }
      for (let i = 0; i < superDropdown.length; i++) {
        if (slug.includes(formatLink(superDropdown[i].headingUri))) {
          return true
        }
      }
    }
    if (!breadcrumb) return false
    return slug.includes(breadcrumb)
  }

  let timeout
  let menuChange

  const onMouseEnter = item => {
    if (item.dropdown || item.superDropdown) {
      clearTimeout(timeout)
    }
    menuChange = setTimeout(() => {
      if (!mobileDevice) {
        if (item.dropdown || item.superDropdown) {
          toggleFirstLoad(false)
          openMenu()
          if (item.dropdown) {
            setDropdownLinks(item.dropdown)
            setSuperDropdownLinks([])
          }
          if (item.superDropdown) {
            setDropdownLinks([])
            setSuperDropdownLinks(item.superDropdown)
          }
        }
      }
    }, 300)
  }

  const onClick = item => {
    if (item.dropdown || item.superDropdown) {
      toggleFirstLoad(false)
      openMenu()
      if (item.dropdown) {
        setDropdownLinks(item.dropdown)
        setSuperDropdownLinks([])
      }
      if (item.superDropdown) {
        setDropdownLinks([])
        setSuperDropdownLinks(item.superDropdown)
      }
    }
  }

  const exitMenu = () => {
    timeout = setTimeout(() => {
      toggleDropDown(false)
      setDropdownLinks(null)
      setSuperDropdownLinks(null)
    }, 150)
  }

  const hideMenu = () => {
    clearTimeout(menuChange)
    timeout = setTimeout(() => {
      toggleDropDown(false)
    }, 150)
  }

  const throttleDropdown = () => {
    toggleStateChange(false)
    setTimeout(() => {
      toggleStateChange(true)
    }, 500)
  }

  const openMenu = () => {
    if (allowStateChange) {
      toggleDropDown(true)
      throttleDropdown()
    }
  }
  return (
    <Container id="navigation-outer-container">
      <AppBar id="navigation-container">
        <FullWidthContainer>
          <Toolbar>
            {links.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <LinkWrapper
                    to={
                      !item.dropdown && !item.superDropdown
                        ? formatLinkGatsby(item.uri)
                        : slug
                    }
                    isActive={
                      item.dropdown || item.superDropdown
                        ? checkBreadcrumb(item.bLink, item.superDropdown)
                        : checkHref(formatLink(item.uri))
                    }
                    firstLink={key === 0}
                    data-testid={`navigation-desktop-link-test`}
                    onMouseEnter={() => onMouseEnter(item)}
                    onClick={() => onClick(item)}
                    onMouseLeave={hideMenu}
                  >
                    <Link isActive={checkHref(formatLink(item.uri))}>
                      {item.title}{" "}
                      {(item.dropdown || item.superDropdown) && (
                        <Arrow
                          alt="arrow"
                          src={arrow}
                          flip={
                            showDropDown &&
                            (JSON.stringify(dropdownLinks) ===
                              JSON.stringify(item.dropdown) ||
                              JSON.stringify(superDropdownLinks) ===
                                JSON.stringify(item.superDropdown))
                          }
                        />
                      )}
                    </Link>
                  </LinkWrapper>
                </React.Fragment>
              )
            })}
          </Toolbar>
        </FullWidthContainer>
        <AnimatedBackground id="animatedBackgroundNav" />
        {!firstLoad && (
          <DropdownMenu
            enquiryHeading={enquiryHeading}
            enquiryText={enquiryText}
            cta={cta}
            links={dropdownLinks}
            superLinks={superDropdownLinks}
            showDropdown={() => {
              clearTimeout(timeout)
              if (showDropDown) {
                clearTimeout(menuChange)
              }
              openMenu()
            }}
            exitMenu={exitMenu}
            hideMenu={hideMenu}
            menuIsVisible={showDropDown}
          />
        )}
      </AppBar>
    </Container>
  )
}

export default Navigation

const Container = styled.div`
  z-index: 9999;
  position: relative;
  margin-bottom: 60px;
  background: ${color.brand.white};
  @media (max-width: 1023px) {
    display: none;
  }
`
const AnimatedBackground = styled.div`
  position: absolute;
  z-index: -1;
  height: 0%;
  width: 100%;
  left: 0;
  top: 0;
  // box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
`

const AppBar = styled.div`
  box-shadow: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  background: ${color.brand.white};
  @media (max-width: 1023px) {
    display: none;
  }
`
const Toolbar = styled.div`
  display: flex;
  flex-grow: 1;
  margin: 0 auto;
  position: relative;
  justify-content: center;

  @media (max-width: 1399px) {
    justify-content: space-between;
  }
`

interface LinkedWrapperProps {
  isActive: boolean
  firstLink?: boolean
}

const LinkWrapper = styled(Lnk)<LinkedWrapperProps>`
  cursor: pointer;
  position: relative;
  text-decoration: none !important;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => (props.firstLink ? "0 2.4rem 0 0" : "0 2.4rem")};
  &:hover,
  &:active,
  &:focus {
    text-decoration: none !important;
  }

  &::after {
    position: absolute;
    content: " ";
    height: 2px;
    width: 0%;
    bottom: -1px;
    left: ${props => (props.firstLink ? "0" : "10%")};
    background: ${color.brand.blue};
    transition: ease-out 0.3s;
    margin: 0 auto;
    ${props => props.isActive && `width: 80%; height: 2.2px;`}
  }

  &:hover::after,
  &:active::after,
  &:focus::after {
    width: 80%;
  }
`
interface ILink {
  isActive?: boolean
}

const Link = styled.p<ILink>`
  color: ${color.brand.blue};
  position: relative;
  font-size: 1.7rem;
  line-height: 2.3rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
`
export const Arrow = styled.img`
  width: 7px;
  margin-left: 1rem;
  transition: all 0.3s ease;

  ${props => props.flip && "transform: rotate(180deg);"}
`
