import Footer from "./Footer"
import React from "react"
import { formatLinkGatsby, get } from "../../util/functions"
import { graphql, useStaticQuery } from "gatsby"

const FooterDrupal = () => {
  const data: any = useStaticQuery(graphql`
    query footer {
      nodeFooter: nodeWhitesFooter {
        whatsApp: field_whatsapp_link_wytes {
          uri
          title
        }
        followTitle: field_social_media_heading
        copyright: field_legal_text {
          value
        }
        bLinks: field_bottom_links {
          uri
          title
        }
        pLinks: field_practice_links {
          uri
          title
        }
        relationships {
          logoBanner: field_logo_banner {
            relationships {
              field_logo_banner_icon {
                field_logo_banner_image {
                  alt
                }
                relationships {
                  field_logo_banner_image {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }
          linkBlocks: field_link_block {
            field_link {
              uri
              title
            }
          }
          sm: field_social_icon {
            field_icon {
              alt
            }
            field_icon_hovered {
              alt
            }
            relationships {
              field_icon {
                localFile {
                  publicURL
                }
              }
              field_icon_hovered {
                localFile {
                  publicURL
                }
              }
            }
            field_link {
              uri
            }
          }
        }
      }
    }
  `)
  const { followTitle, relationships, copyright, pLinks, bLinks, whatsApp } =
    data.nodeFooter || {}
  const { sm, linkBlocks, logoBanner } = relationships
  const logos = logoBanner.relationships.field_logo_banner_icon.map(
    (logo: any) => {
      const icons = get(["relationships", "field_logo_banner_image"], logo, "")
      return icons
    }
  )
  const altLogos = logoBanner.relationships.field_logo_banner_icon[0].field_logo_banner_image.map(
    (item: any) => {
      return item
    }
  )

  const socialMedia = sm.map((media: any) => {
    const uri = media.field_link[0].uri
    const icon = get(
      ["relationships", "field_icon", "localFile", "publicURL"],
      media,
      ""
    )
    const iconHovered = get(
      ["relationships", "field_icon_hovered", "localFile", "publicURL"],
      media,
      ""
    )

    return {
      uri,
      icon,
      iconHovered,
    }
  })

  const socialIconAlt = sm.map((media: any) => {
    const alt = media.field_icon.alt

    return {
      alt,
    }
  })
  const bottomLinks = bLinks.map((link: any) => {
    return {
      title: link.title,
      url: formatLinkGatsby(link.uri),
    }
  })

  const locationLinks = pLinks.map((link: any) => {
    return {
      title: link.title,
      url: formatLinkGatsby(link.uri),
    }
  })

  const links = linkBlocks.map((section: any) => {
    const block = section.field_link.map((links: any) => {
      return {
        title: links.title,
        url: formatLinkGatsby(links.uri),
      }
    })
    return block
  })

  return (
    <Footer
      followTitle={followTitle}
      socialMedia={socialMedia}
      socialIconAlt={socialIconAlt}
      links={links}
      bottomLinks={bottomLinks}
      copyright={copyright.value}
      locationLinks={locationLinks}
      logos={logos[0]}
      altLogos={altLogos}
      whatsApp={whatsApp}
    />
  )
}

export default FooterDrupal
