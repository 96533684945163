import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import FullWidthContainer from "../../helper/FullWidthContainer"
import logo from "../../images/wytes_logo.svg"
import logo2 from "../../images/wytes_logo_white.svg"
import location from "../../white-co-images/location.svg"
import locationOpen from "../../white-co-images/locationOpen.svg"
import arrow from "../../white-co-images/arrow.svg"
import arrowOpen from "../../white-co-images/arrowOpen.svg"
import qcc from "../../white-co-images/careQuality.png"
import { isTablet, isMobile } from "react-device-detect"
import LogoBanner from "../LogoBanner/LogoBanner"
import whatsAppIcon from "../../white-co-images/whatsapp.svg"
import whatsAppIconHovered from "../../white-co-images/whatsappHovered.svg"
import { trackWhatsApp } from "../../util/functions"
import { Link } from "gatsby"

interface ISocialMedia {
  icon: string
  iconHovered: string
  uri: string
}

interface ILink {
  title: string
  url: string
}

interface IBottomLink {
  title: string
  url: string
}

interface ILocationLink {
  title: string
  url: string
}

interface IAlt {
  alt: string
}
interface IFooter {
  followTitle: string
  socialMedia: Array<ISocialMedia>
  socialIconAlt: Array<IAlt>
  links: any
  bottomLinks: Array<IBottomLink>
  copyright: string
  locationLinks: Array<ILocationLink>
}
const Footer = ({
  followTitle,
  socialMedia,
  socialIconAlt,
  links,
  bottomLinks,
  copyright,
  locationLinks,
  logos,
  whatsApp,
  altLogos,
}: IFooter) => {
  const [displayLocations, toggleLocations] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)
  const [firstLoad, toggleFirstLoad] = useState(true)

  const openLocation = () => {
    toggleLocations(!displayLocations)
    toggleFirstLoad(false)
  }

  const toggleIcon = (id: string, on: boolean) => {
    const icon: HTMLElement | null = document.getElementById(id)
    if (icon) {
      if (!on) {
        icon.style.display = "block"
      } else {
        icon.style.display = "none"
      }
    }
  }
  useEffect(() => {
    if (typeof navigator !== "undefined") {
      setMobileDevice(isTablet || isMobile)
    }
  }, [])

  return (
    <>
      <LogoBanner logos={logos} altLogos={altLogos} />
      <Mask locationsOpen={displayLocations} id={"footer"}>
        <MainContainer
          data-testid="footer-container"
          locationsOpen={displayLocations}
          firstLoad={firstLoad}
        >
          <FullWidthContainer>
            <Container>
              <Location>
                <PracticesRow>
                  <Link to={"/"}>
                    <Logo src={displayLocations ? logo2 : logo} />
                  </Link>
                  <Practices
                    locationIsOpen={displayLocations}
                    onClick={openLocation}
                  >
                    <LocationIcon
                      src={displayLocations ? locationOpen : location}
                      alt="Location icon"
                    />
                    Practices
                    <Arrow
                      src={displayLocations ? arrowOpen : arrow}
                      rotateArrow={displayLocations}
                      alt="Arrow"
                      firstLoad={firstLoad}
                    />
                  </Practices>
                </PracticesRow>
                <LocationHiddenContent
                  hideLocations={displayLocations}
                  firstLoad={firstLoad}
                  id="location-hidden-content"
                >
                  <FullWidthContainer>
                    <LocationInner>
                      {locationLinks &&
                        locationLinks.map((link, key) => {
                          return (
                            <div key={key}>
                              <LocationLink key={key} to={link.url}>
                                {link.title}
                              </LocationLink>
                            </div>
                          )
                        })}
                    </LocationInner>
                  </FullWidthContainer>
                </LocationHiddenContent>
              </Location>

              <SectionContainer>
                <SectionIcons>
                  <SocialIconContainer>
                    {followTitle && (
                      <SectionHeading>{followTitle}</SectionHeading>
                    )}

                    {socialMedia &&
                      socialMedia.map((content: ISocialMedia, key: number) => {
                        const id = "s-icon" + key
                        return (
                          <IconsWrapper
                            onMouseEnter={() =>
                              !mobileDevice && toggleIcon(id, true)
                            }
                            onTouchStart={() =>
                              mobileDevice && toggleIcon(id, true)
                            }
                            onMouseLeave={() => toggleIcon(id, false)}
                            onTouchEnd={() => toggleIcon(id, false)}
                            key={key}
                          >
                            <a href={content.uri} key={key} target="_blank">
                              <SIconHovered
                                src={content.iconHovered}
                                alt={socialIconAlt[key].alt}
                              />
                              <SIcon
                                id={id}
                                src={content.icon}
                                alt={socialIconAlt[key].alt}
                              />
                            </a>
                          </IconsWrapper>
                        )
                      })}
                    {isMobile && (
                      <IconsWrapper
                        onClick={trackWhatsApp}
                        onMouseEnter={() =>
                          !mobileDevice && toggleIcon("whatsapp", true)
                        }
                        onTouchStart={() =>
                          mobileDevice && toggleIcon("whatsapp", true)
                        }
                        onMouseLeave={() => toggleIcon("whatsapp", false)}
                        onTouchEnd={() => toggleIcon("whatsapp", false)}
                      >
                        <a
                          href={`${whatsApp.uri}?text=${encodeURI(
                            whatsApp.title
                          )}`}
                          target="_blank"
                        >
                          <SIconHovered
                            src={whatsAppIconHovered}
                            alt={"whatsApp"}
                          />
                          <SIcon
                            id={"whatsapp"}
                            src={whatsAppIcon}
                            alt={"whatsApp"}
                          />
                        </a>
                      </IconsWrapper>
                    )}
                  </SocialIconContainer>
                </SectionIcons>

                <LinksMainWrapper>
                  {links.map((section: any, i: number) => {
                    return (
                      <Section key={i}>
                        {section.map((link: ILink, key: number) => {
                          return (
                            <A
                              style={styles.schedule}
                              key={key}
                              to={link.url}
                              // href={formatLink(link.linkUrl)}
                            >
                              {link.title}
                            </A>
                          )
                        })}
                      </Section>
                    )
                  })}
                </LinksMainWrapper>
              </SectionContainer>
            </Container>
          </FullWidthContainer>
          <SectionLongBottom>
            <FullWidthContainer>
              <InnerLongContainer>
                <ColBottom>
                  <LinksRow>
                    {bottomLinks &&
                      bottomLinks.map((link, key) => {
                        return (
                          <div key={key}>
                            {key !== 0 && <Separator />}

                            <ABottom
                              style={styles.schedule}
                              key={key}
                              to={link.url}
                            >
                              {link.title}
                            </ABottom>
                          </div>
                        )
                      })}
                  </LinksRow>
                  {copyright && (
                    <Copyright
                      dangerouslySetInnerHTML={{ __html: `${copyright}` }}
                    />
                  )}
                </ColBottom>
              </InnerLongContainer>
            </FullWidthContainer>
          </SectionLongBottom>
        </MainContainer>
      </Mask>
    </>
  )
}

export default Footer

const Mask = styled.footer`
  overflow: hidden;
  position: relative;
  ${props =>
    props.locationsOpen
      ? `background: ${color.brand.blue};height:100%;`
      : "background: white;"}
`

const QCC = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    margin-bottom: 4px;
  }
  & img {
    cursor: pointer;
    width: 140px;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    margin-top: 30px;
    & img {
      width: 120px;
    }
  }
`
interface IMainContainer {
  locationsOpen?: boolean
  firstLoad?: boolean
  inView?: boolean
  distance?: number
  goingUp?: boolean
}

const MainContainer = styled.section<IMainContainer>`
  width: 100%;
  position: relative;
  background: white;
  transition: background 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  backface-visibility: hidden;
  padding-top: 5rem;
  ${props =>
    props.locationsOpen
      ? `background-color: #000000;`
      : props.firstLoad
      ? "animation: none;"
      : `background-color: white;`}

  @media (max-width: 1023px) {
    transition: none;
    transform: none;
  }
  @media (max-width: 767px) {
    padding-top: 40px;
  }
`

export const Container = styled.section`
  width: 100%;
  @media only screen and (max-width: 1023px) {
  }
`

export const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1199px) {
    flex-direction: column;
  }
`

const LinksMainWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-wrap: wrap;
  width: 60rem;
  @media (min-width: 2500px) {
    width: 100%;
    max-width: 40vw;
    padding-right: 0rem;
  }
  @media (max-width: 1199px) {
    justify-content: flex-start;
    padding-right: 0rem;
    width: 700px;
  }
  @media (max-width: 767px) {
    display: none;
  }
`

export const SectionLong = styled.div`
  border-bottom: 1px solid #eef2f2;
  @media only screen and (min-width: 1200px) {
    padding-bottom: 10px;
  }
`

export const SectionLongBottom = styled.div`
  padding: 3rem 0 14rem;
  border-top: 1px solid ${color.keyline.grey};
  & p {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.6rem;
    display: block;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
    & a {
      color: ${color.brand.orange};
      text-decoration: none !important;
      font-weight: 500;
    }
  }
  & h2 {
    color: ${color.text.secondary};
    font-size: 16px;
    line-height: 26px;
    display: block;
    margin-bottom: 15px;
    & a {
      color: ${color.brand.orange};
      text-decoration: none !important;
      font-weight: 500;
    }
  }
  @media (max-width: 767px) {
    padding-bottom: 12rem;
  }
`

export const Section = styled.div`
  margin-left: 5rem;
  margin-bottom: 5rem;
  width: 22rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > a:nth-of-type(1) {
    font-weight: 600 !important;
  }
  @media (max-width: 1199px) {
    margin-left: 0;
    margin-right: 5rem;
    width: 255px;
  }
  @media (max-width: 767px) {
    margin-bottom: 0rem;
  }
`
const SectionIcons = styled(Section)`
  margin-left: 0;
  width: 24rem;
  @media (max-width: 1199px) {
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
`

export const SectionHeading = styled.p`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 14px;
`

export const P = styled.p`
  color: ${color.text.secondary};
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  display: block;
  margin-bottom: 15px;
  & a {
    color: ${color.brand.orange};
    text-decoration: none !important;
    font-weight: 500;
  }
  &:nth-of-type(1) {
    font-size: 16px;
  }
`

export const A = styled(Link)`
  color: ${color.text.secondary} !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  margin-bottom: 15px;
  display: block;
  cursor: pointer;
  position: relative;
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &::after {
    position: absolute;
    content: " ";
    height: 2px;
    width: 0%;
    bottom: -1px;
    left: 0;
    background: ${color.text.link};
    transition: ease-out 0.3s;
    margin: 0 auto;
  }
  &:hover::after,
  &:active::after,
  &:focus::after {
    width: 100%;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover,
  &:active,
  &:focus {
    color: ${color.text.link} !important;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 16px;
    line-height: 26px;
  }
`

export const UsefulLinksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const UsefulLinks = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const PhoneNumber = styled.a`
  text-decoration: none;
  font-size: 35px;
  font-weight: bold;
  line-height: 45px;
  display: block;
  color: ${color.brand.blue};
  &:hover,
  &:active {
    text-decoration: none;
  }
  @media only screen and (min-width: 600px) {
    font-size: 29px;
    line-height: 40px;
  }
`

export const SocialIconContainer = styled.div`
  display: block;
  & a {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  & img {
    margin-right: 25px;
  }
`
export const SIconHovered = styled.img`
  position: absolute;
  top: 0px;
  left: 0;
  width: 30px;
  height: 25px;
`

export const SIcon = styled(SIconHovered)``

const IconsWrapper = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
  display: inline-block;
  margin-right: 25px;
`

interface ISocialIcon {
  icon?: string
  iconHovered?: string
}
export const SocialIcon = styled.div<ISocialIcon>`
  cursor: pointer;
  background-image: url(${props => props.icon});
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  margin-right: 30px;
  max-width: 30px;
  width: 30px;
  height: 25px;
  position: relative;
  z-index: 15;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  @media only screen and (max-width: 767px) {
    margin-right: 15px;
  }
  &:hover,
  &:focus {
    background-image: url(${props => props.iconHovered});
  }
  ${SocialIconContainer} a:focus & {
    background-image: url(${props => props.iconHovered});
  }
  ${SocialIconContainer} a:visited & {
    background-image: url(${props => props.icon});
  }
  @media (max-width: 1023px) {
    &:focus {
      background-image: url(${props => props.icon});
    }
  }
`

export const FooterFca = styled.div`
  position: relative;
  display: inline-block;
  //width: 100%;
`
const styles = {
  schedule: {
    lineHeight: "16px",
  },
}
const Location = styled.div`
  margin-bottom: 5rem;
  transition: all 0.5s;
  display: inline-block;
  @media (max-width: 767px) {
    width: 100%;
  }
`
const PracticesRow = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  transition: all 0.5s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  @media (max-width: 767px) {
    justify-content: space-between;
  }
`
interface ILogo {
  src: string
}

const Logo = styled.div<ILogo>`
  height: 7.3rem;
  width: 20rem;
  background-image: url(${props => props.src && props.src});
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    height: 45px;
  }
`
interface IPractices {
  locationIsOpen?: boolean
}
const Practices = styled.div<IPractices>`
  margin-left: 5rem;
  margin-top: 7px;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  color: ${props => (props.locationIsOpen ? "white" : color.brand.blue)};
  @media (max-width: 767px) {
    margin-left: 3rem;
  }
`

const LocationIcon = styled.img`
  width: 12px;
  height: 16px;
  margin-right: 0.5rem;
`
interface IArrow {
  rotateArrow?: boolean

  firstLoad?: boolean
}
const Arrow = styled.img<IArrow>`
  // height: 1rem;
  width: 12px;
  margin-left: 0.5rem;
  transform: rotate(0);
  transition: all 0.5s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${props =>
    props.rotateArrow
      ? "animation: rotateArrow 0.5s ease forwards;"
      : props.firstLoad
      ? ""
      : "animation: rotateArrow 0.5s ease reverse;"}
`
interface ILocationHiddenContent {
  hideLocations?: boolean
  firstLoad?: boolean
}

const LocationHiddenContent = styled.div<ILocationHiddenContent>`
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 555;
  display: flex;
  bottom: 0;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${color.brand.blue};
  transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  padding-top: 5rem;
  height: 84%;
  transform: translateY(100%);
  opacity: 0;
  ${props =>
    props.hideLocations
      ? "transform: translateY(0%); opacity: 1;"
      : props.firstLoad
      ? ""
      : "transform: translateY(100%); opacity: 0;"}
  color: white;
  @media (max-width: 1199px) {
    height: 84%;
  }
  @media (max-width: 1023px) {
    height: 86%;
  }
  @media (max-width: 767px) {
    height: 84%;
  }
`
const LocationInner = styled.div`
  display: flex;
  align-items: flex-start;
  width: 70%;
  flex-wrap: wrap;
  @media (max-width: 1023px) {
    flex-direction: column;
    width: 100%;
  }
  & div {
    color: white;
    width: 50%;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
`
const LocationLink = styled(Link)`
  &,
  &:link,
  &:visited {
    color: white;
    text-decoration: none;
    font-size: 3rem;
    margin-bottom: 4rem;
    display: inline-block;
    font-weight: 300;
    position: relative;
    @media (max-width: 1023px) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }
  &:hover,
  &:active {
    // text-decoration: underline;
  }
  &::after {
    position: absolute;
    content: " ";
    height: 2px;
    width: 0%;
    bottom: -8px;
    left: 0;
    background: white;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    margin: 0 auto;
  }
  &:hover::after,
  &:active::after,
  &:focus::after {
    width: 100%;
  }
`

const InnerLongContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const LinksRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
const ABottom = styled(A)`
  &,
  &:visited,
  &:link {
    font-size: 1.5rem !important;
    line-height: 2.5rem !important;
    margin-bottom: 0;
    &:first-of-type {
      font-weight: 300;
    }
    @media (max-width: 767px) {
      margin-bottom: 1rem;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }
`

interface ISeparator {
  displayOnMobile?: boolean
}
const Separator = styled.div<ISeparator>`
  height: 1rem;
  width: 1px;
  background: black;
  display: inline-block;
  margin: 0 1rem;
  @media (max-width: 767px) {
    display: ${props => (props.displayOnMobile ? "inline-block" : "none")};
  }
`
const Copyright = styled.div`
  & p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  & p:first-child {
    font-size: 1.4rem;
  }
  & b,
  & strong {
    margin-bottom: 1rem;
  }
`
const ColBottom = styled.div`
  width: 70%;
  @media (max-width: 767px) {
    width: 100%;
  }
`
