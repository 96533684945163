import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { formatLink, formatLinkGatsby, setItem } from "../../util/functions"
import Btn from "../Button/Button"
import color from "../../style/color"
import { Link } from "gatsby"
import Modal from "../../components/Modal/Modal"
import RadioBtn from "../../components/Form/RadioButton"
import ConsultationModalDrupal from "../../components/VideoConsultation/ConsultationModalDrupal"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../Button/Button"

interface DropdownMenuProps {
  links?: Array<any>
  superLinks?: any
  exitMenu: any
  showDropdown: any
  menuIsVisible: boolean
  hideMenu: any
}
interface Option {
  label: string
  value: string
}
const DropdownMenu = ({
  links,
  superLinks,
  exitMenu,
  showDropdown,
  enquiryHeading,
  enquiryText,
  cta,
  menuIsVisible,
  hideMenu,
}: DropdownMenuProps) => {
  const [showModal, toggleModal] = useState(false)
  const [practice, setPractice] = useState("")
  const data: any = useStaticQuery(graphql`
    query practicesDropdown3 {
      nodeWhitesPractices {
        field_practice
      }
    }
  `)
  const practices: Option[] = data.nodeWhitesPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )

  return (
    <>
      <DropDown
        onMouseEnter={showDropdown}
        onMouseLeave={hideMenu}
        menuIsVisible={menuIsVisible}
      >
        {links && links.length > 0 && (
          <>
            <LinksContainer>
              <InnerContainer>
                <LinkWrapaper>
                  {links.map(link => {
                    return (
                      <PageLinkWrapper key={Math.random()}>
                        <PageLink
                          to={formatLinkGatsby(link.uri)}
                          key={Math.random()}
                          data-testid="dropdown-link"
                        >
                          {link.title}
                        </PageLink>
                      </PageLinkWrapper>
                    )
                  })}
                </LinkWrapaper>
              </InnerContainer>
            </LinksContainer>

            <CtaContainer>
              <div>
                <h3>{enquiryHeading}</h3>
                <p>{enquiryText}</p>
              </div>
              {cta && (
                <BtnContainer>
                  <Btn text={cta.title} href={cta.uri} size="small" />
                </BtnContainer>
              )}
            </CtaContainer>
          </>
        )}
        {superLinks && superLinks.length > 0 && (
          <>
            <SuperLinksContainer>
              {superLinks.map((column: any, i: number) => {
                return (
                  <OuterColumn
                    key={Math.random()}
                    data-testid="dropdown-column"
                  >
                    <HeadingContainer id={`column-heading-container-${i}`}>
                      <ColumnHeading
                        to={formatLinkGatsby(column.headingUri)}
                        id={`column-heading-${i}`}
                      >
                        {column.heading}
                      </ColumnHeading>
                    </HeadingContainer>
                    {column.links.map((section: any) => {
                      return (
                        <LinksColumn
                          key={Math.random()}
                          data-testid="dropdown-column-section"
                        >
                          <ColumnSubHeading>
                            {section.subheading}
                          </ColumnSubHeading>

                          {section.links.map((link: any, key) => {
                            if (section.videoConsultation) {
                              return (
                                <VideoLink
                                  key={key}
                                  onClick={() => {
                                    hideMenu()
                                    toggleModal(true)
                                    setPractice("")
                                  }}
                                >
                                  {link.title}
                                </VideoLink>
                              )
                            }
                            return (
                              <DropdownLink
                                to={formatLinkGatsby(link.uri)}
                                key={Math.random()}
                                data-testid="dropdown-column-innerlink"
                              >
                                {link.title}
                              </DropdownLink>
                            )
                          })}
                        </LinksColumn>
                      )
                    })}
                  </OuterColumn>
                )
              })}
            </SuperLinksContainer>
            <CtaContainer>
              <div>
                <h3>{enquiryHeading}</h3>
                <p>{enquiryText}</p>
              </div>
              {cta && (
                <BtnContainer>
                  <Btn text={cta.title} href={cta.uri} size="small" />
                </BtnContainer>
              )}
            </CtaContainer>
          </>
        )}
      </DropDown>

      {<Overlay menuIsVisible={menuIsVisible} />}
      <Modal showModal={showModal} toggleModal={() => toggleModal(false)}>
        <ModalInner>
          <p>Please select a practice to book a Video Consultation:</p>
          <RadioBtnsContainer>
            {practices &&
              practices.map((item, key) => {
                let formatItem = item.value
                  ?.replace(/[^A-Z0-9]+/gi, "-")
                  .toLowerCase()

                // const formatPractice: string = formatItem?.toLowerCase()
                return (
                  <RadioBtn
                    small
                    key={key}
                    onClick={() => {
                      setPractice(formatItem)
                    }}
                    isActive={practice === formatItem}
                  >
                    {item.value}
                  </RadioBtn>
                )
              })}
          </RadioBtnsContainer>
          {practice !== "" && (
            <ModalBtnContainer>
              <Button
                text={"Continue"}
                onClick={() => {
                  setItem("video", practice)
                  window.location.href =
                    process.env.BASE === "/"
                      ? `/general-dentistry/video-consultation?practice=${practice}`
                      : process.env.BASE +
                        `/general-dentistry/video-consultation?practice=${practice}`
                }}
              />
            </ModalBtnContainer>
          )}
        </ModalInner>
      </Modal>
    </>
  )
}

interface IOverlay {
  menuIsVisible?: boolean
}
const Overlay = styled.div<IOverlay>`
  position: absolute;
  top: 60px;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100%;
  display: none;
  overflow: hidden;
  ${props => (props.menuIsVisible ? "display: block;" : "display: none;")}
`

const CtaContainer = styled.div`
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 5% 2.1rem;

  @media (max-width: 1199px) {
    padding: 1.8rem 3% 2.1rem;
  }
  & div {
    & h3 {
      font-family: Raleway;
      font-weight: 400;
      font-size: 2rem;
      line-height: 3rem;
    }

    & p {
      font-size: 1.5rem;
      line-height: 2.5rem;
    }
  }
`
const BtnContainer = styled.div`
  width: 164px;
`

const OuterColumn = styled.div`
  flex: 1;
  margin-right: 4.3rem;
  padding-top: 4.3rem;
  padding-bottom: 2rem;
`
interface ILink {
  firstLoad?: boolean
}
const DropdownLink = styled(Link)<ILink>`
  &:visited,
  &:link {
    color: ${color.brand.blue};
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
`

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2.3rem 0;
  align-items: flex-start;
`

const HeadingContainer = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${color.keyline.nav};
`

const ColumnHeading = styled(Link)`
  &:visited,
  &:link {
    color: ${color.brand.blue};
    font-size: 2rem;
    font-weight: 300;
    line-height: 3rem;
    text-decoration: none;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
    opacity: 1;
  }
`

const ColumnSubHeading = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.3rem;
  line-height: 2.3rem;
  text-transform: uppercase;
  opacity: 1 !important;
`

const PageLinkWrapper = styled.div`
  width: 25%;
`

const PageLink = styled(Link)`
  text-decoration: none;
  margin: 0;
  &:visited,
  &:link {
    font-size: 1.8rem;
    line-height: 2.8rem;
    text-decoration: none;
  }
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
  display: inline;
  margin-bottom: 10px;
`
const LinkWrapaper = styled.div`
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const LinksContainer = styled.div`
  width: 100%;
  text-align: left;
  margin: 0 auto;
  padding: 0 5%;

  @media only screen and (max-width: 1199px) {
    padding: 0 3%;
  }
`
const InnerContainer = styled.div`
  padding: 4rem 0 9.3rem;
`
const SuperLinksContainer = styled(LinksContainer)`
  display: flex;
  justify-content: space-between;
`
interface IDropDown {
  menuIsVisible?: boolean
}
const DropDown = styled.div<IDropDown>`
  border-top: 0.5px solid ${color.keyline.nav};
  background: ${color.background.blueLight};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 999;
  transform-origin: top;
  ${props =>
    props.menuIsVisible
      ? "animation: animateDropdownNav 0.4s ease-in forwards"
      : "animation: animateDropdownNavReverse 0.4s ease-in forwards"};
`
const VideoLink = styled.p`
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 2.5rem;
  &:hover {
    text-decoration: underline;
  }
`
const ModalInner = styled.div`
  & p {
    margin-bottom: 3rem;
    font-weight: 400;
  }
`

const RadioBtnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ModalBtnContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`

export default DropdownMenu
