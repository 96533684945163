import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Navigation from "./Navigation"
import MobileNavigation from "./MobileNavigation"

interface NavigationProps {
  slug: string
  enquiryHeading?: string
  enquiryText?: string
  menuIsOpen: boolean
  cta: any
  toggleMenu: any
  links: any
}

const NavigationContainer = ({
  slug,
  menuIsOpen,
  toggleMenu,
  links,
  enquiryHeading,
  enquiryText,
  cta,
}: NavigationProps) => {
  return (
    <>
      <Navigation
        links={links}
        slug={slug}
        enquiryHeading={enquiryHeading}
        enquiryText={enquiryText}
        cta={cta}
      />
      <MobileNavigation
        menuIsOpen={menuIsOpen}
        toggleMenu={toggleMenu}
        links={links}
        enquiryHeading={enquiryHeading}
        enquiryText={enquiryText}
        cta={cta}
      />
    </>
  )
}

export default NavigationContainer
