import React, { useState } from "react"
import styled from "styled-components"
import cross from "../../images/cross.svg"

interface ModalProps {
  showModal: boolean
  toggleModal(state: boolean): void
  children: any
}

interface OuterProps {
  showModal?: boolean
}
const ModalContainer = ({ children, showModal, toggleModal }: ModalProps) => {
  return (
    <OuterContainer showModal={showModal} onClick={() => toggleModal(false)}>
      <Modal onClick={e => e.stopPropagation()}>
        <Cross src={cross} onClick={() => toggleModal(false)} />
        {children}
      </Modal>
    </OuterContainer>
  )
}

const Cross = styled.img`
  cursor: pointer;
  width: 20px;
  position: absolute;
  top: 50px;
  right: 50px;

  @media only screen and (max-width: 850px) {
    top: 30px;
    right: 30px;
  }
`

const OuterContainer = styled.div<OuterProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: ${props => (props.showModal ? "flex" : "none")};
  align-items: flex-start;
  justify-content: center;
  z-index: 1500;
  padding: 160px 25px;
  overflow-y: auto;
  @media only screen and (max-width: 850px) {
    padding: 90px 15px;
  }
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 50px;
  padding-top: 60px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  width: 90%;
  max-width: 720px;
  position: relative;
  z-index: 1000;

  @media only screen and (max-width: 850px) {
    padding: 70px 30px 50px;
    border-radius: 0;
  }
`

export default ModalContainer
