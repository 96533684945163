import React, { useState } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { formatLinkGatsby, formatLink } from "../../util/functions"
import arrow from "../../white-co-images/burger-arrow.svg"
import { navigate } from "gatsby"

interface BurgerMenuLinkProps {
  link: string | null
  text: string
  dropdown?: any
  superDropdown?: any
  setBackFunction?: any
  setDropdownLinks?: any
  linkOnClick?: any
  openModal?: any
}

const BurgerMenuLink = ({
  link,
  text,
  dropdown,
  superDropdown,
  setBackFunction,
  setDropdownLinks,
  linkOnClick,
  openModal,
}: BurgerMenuLinkProps) => {
  const [showDropdown, toggleDropdown] = useState(false)
  return (
    <LinkWrapper
      data-testid="burgermenu-link"
      flip={showDropdown}
      isLink={link}
      onClick={e => {
        if (linkOnClick) {
          return linkOnClick(e)
        }

        if (
          link &&
          formatLink(link) === `/general-dentistry/video-consultation`
        ) {
          return openModal()
        }
        if (link) {
          window.location.href = formatLink(link)
        }

        if (dropdown) {
          setDropdownLinks(dropdown, false, text)
        }
        if (superDropdown) {
          setDropdownLinks(superDropdown, true, text, link)
        }
      }}
    >
      <p>{text}</p>{" "}
      {(dropdown || superDropdown || linkOnClick) && (
        <Arrow src={arrow} flip={showDropdown} />
      )}
    </LinkWrapper>
  )
}

interface ILinkWrapper {
  flip?: boolean
  isLink?: boolean
}
const LinkWrapper = styled.a<ILinkWrapper>`
  &,
  &:link,
  &:visted {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    outline: none !important;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  cursor: pointer;
  color: ${color.brand.blue} !important;
  font-size: 16px;
  line-height: 26px;
  text-decoration: none !important;
  padding: 20px 25px;
  background-color: ${color.background.blueLight};
  border-bottom: 1.25px solid ${color.brand.green};
  transition: padding 0.3s ease;
  -webkit-text-size-adjust: 100%;
  -webkit-transform: translateZ(0px);
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-perspective: 1000;
  z-index: 15;
  cursor: default;
  &:active {
    font-weight: bold;
    background-color: ${color.brand.green};
  }
  display: flex;
  justify-content: ${props => !props.flip && "space-between"};
  & p {
    font-size: 18px;
    line-height: 28px;
    ${props => props.flip && `font-weight: bold;`}
  }

  &:first-of-type {
    border-top: 1.25px solid ${color.brand.green};
  }
`

const Arrow = styled.img<ILinkWrapper>`
  transform: rotate(-90deg) translateX(-50%);
  height: 12px;
  ${props =>
    props.flip &&
    `
    transform: rotate(90deg) translateY(50%);
     position: absolute;
      left: 25px;
      bottom: 50%;
    `};
`

export default BurgerMenuLink
