import React from "react"
import styled from "styled-components"
import color from "../../style/color"

interface RadioBtnProps {
  children: React.ReactNode
  onClick(): void
  isActive: boolean
  small?: boolean
}

interface InputProps {
  isActive: boolean
  small?: boolean
}

const RadioBtn = ({ children, onClick, isActive, small }: RadioBtnProps) => {
  return (
    <Input
      onClick={onClick}
      isActive={isActive}
      tabIndex={1}
      small={small}
      tabIndex={0}
    >
      {children}
    </Input>
  )
}

export default RadioBtn

const Input = styled.div<InputProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px 12px 0;
  border: 1px solid #cdcdcd;
  background-color: white;
  cursor: pointer;
  height: 100%;
  color: ${color.text.primary};
  font-size: 1.4rem;
  line-height: 1.9rem;
  outline: none;
  padding: ${props => (props.small ? "1.5rem" : "2.1rem 4rem")};
  min-width: 14rem;
  transition: transform 200ms ease, background-color 200ms ease,
    color 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;

  & strong,
  & b {
    font-weight: bold;
  }

  ${props =>
    props.isActive &&
    `background-color: ${color.support.green};
    border: 1px solid ${color.support.green};
    color: white;
    `};

  &:hover {
    border: 1px solid ${color.support.radioBtnHover};
    background-color: ${color.support.radioBtnHover};
    color: white;
  }

  &:active {
    transform: translate(0px, 3px);
  }

  @media (max-width: 600px) {
    padding: 11px 15px;
    font-size: 12px;
    line-height: 22px;
    margin: 0 8px 8px 0;
  }
`
