import React from "react"
import styled from "styled-components"
import RegularSection from "../../helper/RegularSection"
import color from "../../style/color"

const LogoBanner = ({ logos, altLogos }) => {
  return (
    <Container>
      {logos.length > 0 && (
        <RegularSection>
          <LogosContainer>
            {logos.map((logo, key) => {
              return altLogos[key].alt === "Care Quality Commission" ? (
                <a
                  href="https://www.cqc.org.uk/location/1-232488542?referer=widget4"
                  target="_blank"
                  key={key}
                >
                  <Logo
                    src={logo.localFile.publicURL}
                    alt={altLogos[key].alt}
                  />
                </a>
              ) : (
                <Logo
                  key={key}
                  src={logo.localFile.publicURL}
                  alt={altLogos[key].alt}
                />
              )
            })}
          </LogosContainer>
        </RegularSection>
      )}
    </Container>
  )
}

export default LogoBanner

export const Container = styled.div`
  background-color: ${color.background.logoBanner};
  padding: 26px 0;

  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  @media only screen and (min-width: 1200px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 20px 0;
  }
`

export const LogosContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  & > a {
    width: auto;
    height: 44px;

    @media only screen and (max-width: 767px) {
      height: 16px;
    }
  }
`

export const Logo = styled.img`
  width: auto;
  height: 44px;

  @media only screen and (max-width: 767px) {
    height: 16px;
  }
`
