import React from "react"
import styled from "styled-components"
import logo from "../../images/wytes_logo.svg"
import FullWidthContainer from "../../helper/FullWidthContainer"
import Button from "../Button/Button"
import color from "../../style/color"
import { scrollPage } from "../../util/functions"
import { Link } from "gatsby"

interface ILogoTop {
  url?: string
  alt?: string
}

const LogoTop = ({ url = process.env.BASE, alt = "Wytes logo" }: ILogoTop) => {
  return (
    <Container id="logoTop-container">
      <FullWidthContainer>
        <LeftCol to={"/"}>
          <Logo
            src={logo}
            alt={alt}
            data-testid="logo"
            onClick={() => scrollPage("logoTop-container")}
          />
        </LeftCol>
        <RightCol>
          <BtnContainer data-testid="logoTopBtn">
            <Button
              text="Enquire now"
              href={
                (process.env.BASE === "/" ? "" : process.env.BASE) +
                "/general-enquiry"
              }
              secondary
              size="small"
            />
          </BtnContainer>
        </RightCol>
      </FullWidthContainer>
    </Container>
  )
}

export default LogoTop

const Container = styled.div`
  padding: 35px 0 15px;
  background: white;
  position: relative;
  z-index: 999;
  @media (max-width: 1023px) {
    display: none;
  }
`

const Logo = styled.img`
  width: auto;
  height: 58px;
  cursor: pointer;
`

const LeftCol = styled(Link)`
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`

const RightCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`
const BtnContainer = styled.div`
  width: 15rem;
`
