import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link, Image, RightCol, AppBar, Toolbar } from "./MobileNavigation"
import logo from "../../images/wytes_logo.svg"
import color from "../../style/color"
import { formatLink, setItem } from "../../util/functions"
import Button from "../Button/Button"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import BurgerMenuLink from "./BurgerMenuLink"
import arrow from "../../white-co-images/burger-arrow.svg"
import Modal from "../../components/Modal/Modal"
import RadioBtn from "../../components/Form/RadioButton"
import { graphql, useStaticQuery } from "gatsby"

interface BurgerMenuProps {
  links: any
  closeMenu: any
  scrollValue: number
  enquiryHeading: string
  enquiryText: string
  cta: any
  menuIsOpen: boolean
}

const BurgerMenu = ({
  links,
  closeMenu,
  scrollValue,
  enquiryHeading,
  enquiryText,
  cta,
  menuIsOpen,
}: BurgerMenuProps) => {
  const [showModal, toggleModal] = useState(false)
  const [practice, setPractice] = useState("")

  const data: any = useStaticQuery(graphql`
    query practicesDropdown4 {
      nodeWhitesPractices {
        field_practice
      }
    }
  `)
  const practices: Option[] = data.nodeWhitesPractices.field_practice.map(
    practice => {
      return { label: practice, value: practice }
    }
  )
  useEffect(() => {
    if (menuIsOpen) {
      const scrollable = document.getElementById("menu-mob-scrollable")
      const body = document.querySelector("body")

      disableBodyScroll(scrollable)

      return () => {
        enableBodyScroll(body)
        clearAllBodyScrollLocks()
        document.documentElement.scrollTop = document.body.scrollTop = scrollValue
      }
    }
  }, [menuIsOpen])

  const [currentLinks, setCurrentLinks] = useState(links)
  const [backFunction, setBackFunction] = useState(null)
  const [heading, setHeading] = useState(null)
  const [headingLink, setHeadingLink] = useState(null)

  const resetLinks = () => {
    setCurrentLinks(links)
    setBackFunction(null)
    setHeading(null)
  }

  const setDropdownLinks = (
    newLinks,
    superDropdown,
    heading,
    headingLink = null
  ) => {
    setCurrentLinks(newLinks)
    setHeading(heading)
    setBackFunction(() => resetLinks)
  }

  return (
    <>
      {menuIsOpen && (
        <Container>
          <AppBar>
            <Toolbar transparent>
              <Link href={process.env.BASE}>
                <Image src={logo} alt={"Wytes logo"} />
              </Link>
              <RightCol>
                <Menu onClick={closeMenu}>
                  <Line first />
                  <Line second />
                  <Line third />
                </Menu>
              </RightCol>
            </Toolbar>
          </AppBar>
          <LinksContainer id="menu-mob-scrollable">
            <LinkContainer>
              {backFunction !== null && (
                <BackLink onClick={backFunction}>
                  <Arrow src={arrow} /> Back
                </BackLink>
              )}
              {heading && (
                <LinkHeading isLink={headingLink} href={headingLink}>
                  {heading}
                </LinkHeading>
              )}
              {currentLinks.map((item, key) => {
                return (
                  <BurgerMenuLink
                    setDropdownLinks={setDropdownLinks}
                    setBackFunction={setBackFunction}
                    link={
                      !item.superDropdown && !item.dropdown
                        ? formatLink(item.uri)
                        : null
                    }
                    key={Math.random()}
                    text={item.title || item.heading}
                    dropdown={item.dropdown}
                    superDropdown={item.superDropdown}
                    openModal={() => {
                      toggleModal(true)
                      closeMenu()
                      setPractice("")
                    }}
                    linkOnClick={
                      item.links &&
                      (e => {
                        e.preventDefault()
                        if (item.links) {
                          const newLinks = []
                          for (let i = 0; i < item.links.length; i++) {
                            for (
                              let j = 0;
                              j < item.links[i].links.length;
                              j++
                            ) {
                              newLinks.push(item.links[i].links[j])
                            }
                          }
                          setHeading(item.heading)
                          setHeadingLink(formatLink(item.headingUri))
                          setCurrentLinks(newLinks)
                          setBackFunction(() => () => {
                            setCurrentLinks(currentLinks)
                            setBackFunction(() => resetLinks)
                            setHeading(heading)
                            setHeadingLink(null)
                          })
                        }
                      })
                    }
                  />
                )
              })}
            </LinkContainer>

            <Enquiry>
              <h6>{enquiryHeading}</h6>
              <p>{enquiryText}</p>
              {cta && <Button text={cta.title} href={cta.uri} />}
            </Enquiry>
          </LinksContainer>
        </Container>
      )}
      <Modal showModal={showModal} toggleModal={() => toggleModal(false)}>
        <ModalInner>
          <p>Please select a practice to book a Video Consultation:</p>
          <RadioBtnsContainer>
            {practices &&
              practices.map((item, key) => {
                let formatItem = item.value
                  ?.replace(/[^A-Z0-9]+/gi, "-")
                  .toLowerCase()

                return (
                  <RadioBtn
                    small
                    key={key}
                    onClick={() => {
                      setPractice(formatItem)
                    }}
                    isActive={practice === formatItem}
                  >
                    {item.value}
                  </RadioBtn>
                )
              })}
          </RadioBtnsContainer>
          {practice !== "" && (
            <ModalBtnContainer>
              <Button
                text={"Continue"}
                onClick={() => {
                  setItem("video", practice)
                  window.location.href =
                    process.env.BASE === "/"
                      ? `/general-dentistry/video-consultation?practice=${practice}`
                      : process.env.BASE +
                        `/general-dentistry/video-consultation?practice=${practice}`
                }}
              />
            </ModalBtnContainer>
          )}
        </ModalInner>
      </Modal>
    </>
  )
}

export default BurgerMenu

const BackLink = styled.div`
  padding: 20px 25px;
  background: ${color.brand.green};
  color: ${color.brand.blue};
  cursor: pointer;
  border-top: 1.25px solid ${color.brand.green};
  border-bottom: 1.25px solid ${color.brand.green};
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
`

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`
interface ILinkHeading {
  isLink?: boolean
}

const LinkHeading = styled.a<ILinkHeading>`
  font-size: 18px;
  line-height: 28px;
  padding: 10px 25px;
  background: ${color.brand.white};
  color: ${color.brand.blue};
  font-weight: 500;
  border-bottom: 1.25px solid ${color.brand.green};

  &:link,
  &:visited {
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    font-weight: 500;
  }

  &:hover,
  &:active {
    // color: ${color.background.lightGrey};
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`
interface ILine {
  first?: boolean
  second?: boolean
  third?: boolean
  menuIsOpen?: boolean
}
const Line = styled.div<ILine>`
  width: 27px;
  height: 2px;
  margin: ${props => props.second && "5px 0"};
  background-color: #000;
  width: ${props => props.menuIsOpen && "15px"};

  transform-style: preserve-3d;
  transition: all 200ms ease 0s;
  opacity: 0;
  will-change: transform;
  backface-visibility: hidden;
`
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 5;
`
const Enquiry = styled.div`
  flex: 1;
  padding: 18px 25px 73px;
  background: ${color.background.white};

  font-size: 15px;
  line-height: 25px;

  & h6 {
    font-family: Raleway;
    font-weight: 400;
    margin-bottom: 6px;
  }

  & p {
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 8px;
  }
`

const Arrow = styled.img`
  height: 12px;
  transform: rotate(90deg);
  margin-right: 5px;
`
const RadioBtnsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & p {
    margin-bottom: 2rem;
  }
`
const ModalInner = styled.div`
  & p {
    margin-bottom: 3rem;
    font-weight: 400;
  }
`

const ModalBtnContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
`
